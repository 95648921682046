<template>
<div style="padding: 10px">
  <div style="margin-bottom: 10px">
    <Button type="primary" @click="goBack()">返回</Button>
  </div>
  <Form :label-width="100" ref="paperForm">
    <Row style="min-height: 90vh">
      <Col span="10" style="border-right: 1px solid #999">
        <div style="margin-bottom: 30px;font-weight: bold">基本信息</div>
        <FormItem label="问卷名称">
          <Input v-model="formItem.paper_name" style="width: 80%"></Input>
        </FormItem>

        <FormItem label="封面图片">
          <Upload
              name="img"
              ref="upload"
              :show-upload-list="false"
              :default-file-list="defaultList"
              :format="['jpg','jpeg','png']"
              :on-format-error="handleFormatError"
              :before-upload="handleBeforeUpload"
              action="#"
              style="display: inline-block;border: 1px dashed #ccc">
            <div style="width: 58px;height:58px;line-height: 58px;text-align: center;background-color: #fff">
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
        </FormItem>

        <FormItem>
          <Row :gutter="10">
            <Col span="6" v-for="(item,index) in defaultList" :key="index" style="position:relative;">
              <div @click="delAttach()" style="position: absolute;right: -5px;top:-5px;width: 20px;height: 20px;background-color: #515a6e;border-radius: 50%;color: #fff;line-height: 20px;text-align: center">X</div>
              <img :src="item.url" alt="" style="width: 100%;height: auto">
            </Col>
          </Row>
        </FormItem>

        <FormItem label="问卷简介">
          <Input type="textarea" :rows="4" v-model="formItem.paper_intro" style="width: 80%" size="large"></Input>
        </FormItem>

        <FormItem>
          <Button size="large" type="primary" @click="submit()" :disabled="btnDisabled">确定</Button>
        </FormItem>
      </Col>
      <Col span="12" style="padding-left: 30px">
        <div style="margin-bottom: 30px;font-weight: bold">问卷表单</div>
        <div style="margin-bottom: 15px" v-for="(item, index) in formItem.form" :key="index">
          <template v-if="item.paper_form_type === 'text'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999;">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <Input style="width: 80%;margin-top: 5px"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>

          </template>
          <template v-else-if="item.paper_form_type === 'textarea'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <Input type="textarea" :rows="4" style="width: 80%;margin-top: 5px"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>

          </template>
          <template v-else-if="item.paper_form_type === 'radio'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <Input style="margin-top: 5px" v-for="(optionItem,optionIndex) in item.options" :key="'optionItem'+optionIndex" v-model="item.options[optionIndex]">
                  <Button slot="prepend" icon="md-close" @click="delOption(index,optionIndex)"></Button>
                  <Button slot="append" icon="md-add" v-if="optionIndex === item.options.length - 1" @click="addOptions(index)"></Button>
                </Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>
          </template>
          <template v-else-if="item.paper_form_type === 'checkbox'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <Input style="margin-top: 5px" v-for="(optionItem,optionIndex) in item.options" :key="'optionItem'+optionIndex" v-model="item.options[optionIndex]">
                  <Button slot="prepend" icon="md-close" @click="delOption(index,optionIndex)"></Button>
                  <Button slot="append" icon="md-add" v-if="optionIndex === item.options.length - 1" @click="addOptions(index)"></Button>
                </Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>
          </template>

          <template v-else-if="item.paper_form_type === 'date'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <DatePicker type="date" placeholder="Select date" style="width: 200px;margin-top: 5px"></DatePicker>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>
          </template>

          <template v-else-if="item.paper_form_type === 'upload'">
            <div style="display: flex;">
              <div style="width: 80%;padding: 10px;border:1px solid #999">
                <Input v-model="item.paper_form_name" style="width: 80%"></Input>：
                <Select style="width:15%;margin-top: 5px" v-model="item.paper_form_type">
                  <Option value="text" >文本框</Option>
                  <Option value="textarea" >长文本</Option>
                  <Option value="radio" >单选</Option>
                  <Option value="checkbox" >多选</Option>
                  <Option value="date" >日期</Option>
                  <Option value="upload" >上传</Option>
                </Select>
                <Input style="width: 200px;margin-top: 5px" placeholder="上传图片"></Input>
              </div>
              <div style="flex: 1;padding: 10px;display: flex;justify-content: space-around;align-items: center;flex-direction: column;border:1px solid #999">
                <InputNumber size="small" :max="10" :min="1" v-model="item.paper_form_order" @on-blur="changeFormItemOrder"></InputNumber>
<!--                <Button size="small" type="error" @click="delFormItem(index)">删除</Button>-->
              </div>
            </div>
          </template>

        </div>
      </Col>
    </Row>
  </Form>
</div>
</template>

<script>
import api from "../api/xiaochengxu";
import lrz from "lrz";
export default {
  name: "Gzhpapermod",
  data: function(){
    return {
      formItem: {
        paper_name: '',
        paper_img: '',
        paper_intro: '',
        form: []
      },
      user_id: '',
      paper_id: '',
      defaultList: [],
    }
  },
  computed:{
    btnDisabled: function(){
      return this.formItem.paper_name === '' ||
          this.formItem.paper_img === '' ||
          this.formItem.paper_intro === '' ||
          this.formItem.form.length < 1
    }
  },
  methods: {
    goBack: function(){
      this.$router.replace({path: '/gzhpaper', query: {id: this.user_id}})
    },
    delOption: function(questionIndex,optionIndex){
      this.formItem.form[questionIndex].options.splice(optionIndex, 1)
    },
    addOptions: function(index){
      this.formItem.form[index].options.push('')
    },
    changeFormItemOrder: function(){
      this.formItem.form.sort(function(a, b) {
        return a.paper_form_order - b.paper_form_order
      })
    },
    delAttach: function(){
      this.formItem.paper_img = ''
      this.defaultList = [];
    },
    handleFormatError: function(){
      this.$Message.error('请上传jpg、jpeg、png格式的图片文件。');
    },
    handleBeforeUpload: function(file){
      const that = this;
      lrz(file,{fieldName: 'file'}).then(res =>{
        that.$http.post(api.upload, res.formData).then(response => {
          if(response.data.success) {
            this.formItem.paper_img = response.data.path;
            this.defaultList = [];
            this.defaultList.push({
              name: response.data.path,
              url: response.data.path
            })
          } else {
            this.$Message.error(response.data.err);
            console.log(response);
          }
        })
      })
    },
    submit: function(){
      this.$Modal.confirm({
        content: '<p>修改后当前问卷将重新进入审核状态。</p>',
        onOk: () => {
          this.$http.put(api.gzhModPaper, {info: this.formItem}).then(response => {
            if(response.data.success) {
              this.$Message.success('修改成功');
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });


    }
  },
  mounted() {
    this.paper_id = this.$route.query.id;
    this.user_id = this.$route.query.uid;
    this.$http.get(api.gzhGetPaperDetail, {params: {
      id: this.paper_id,
      uid: this.user_id
    }}).then(response => {
      if(response.data.success) {
        this.formItem = response.data.res;
        this.defaultList = [{
          name: response.data.res.paper_img,
          url: response.data.res.paper_img
        }]
      } else {
        this.$Message.error(response.data.err);
        console.log(response);
      }
    })
  }
}
</script>

<style scoped>

</style>